import {
  Box,
  Breadcrumbs,
  Button,
  Chip,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Input,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField
} from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import { Formik } from 'formik';
import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import * as Yup from 'yup';

import { useDispatch } from 'react-redux';
import { useMutation, useQuery } from 'react-query';
import { showSnackbar } from '../../../components/snackbar/actions';
import UploadProgress from './UploadProgress';
import UploadStudyModal from './UploadStudyModal';
import { useApi } from '../../../config/api';

const symptomsList = [
  'Seizure',
  'Fits',
  'Memory Loss',
  'Tremor',
  'Involuntry Movements',
  'Dizziness',
  'Headache',
  'Others'
];

const marginStyles = {
  marginTop: '15px',
  marginBottom: '10px'
};

const uploadSchema = Yup.object().shape({
  siteId: Yup.string().required('Please Select Site.'),
  patientAge: Yup.number().min(3, 'Age sholud not be below 3').required('Please Enter Age.'),
  patientSex: Yup.string().required('Required')
});

function UploadStudy() {
  const [progress, setProgress] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const fileRef = useRef(null);
  const dropZoneRef = useRef(null);

  const [formValues, setFormValues] = useState({
    siteId: '',
    patientAge: '',
    patientSex: '',
    symptoms: [],
    condition: '',
    ethnicity: '',
    refPhysician: '',
    caseFiles: []
  });

  const dispatch = useDispatch();
  const { api, apiEndpoints } = useApi();

  const handleChange = (e) => {
    const { name, value, files } = e.target;

    const inputElement = e.target;
    if (inputElement.type === 'text' || inputElement.tagName === 'textarea') {
      const caret = inputElement.selectionStart;
      window.requestAnimationFrame(() => {
        inputElement.selectionStart = caret;
        inputElement.selectionEnd = caret;
      });
    }
    if (name === 'caseFiles') setFormValues((prev) => ({ ...prev, [name]: files }));
    else setFormValues((prev) => ({ ...prev, [name]: value }));
  };
  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const { files } = e.dataTransfer;
    setFormValues((prev) => ({ ...prev, caseFiles: files }));
  };

  const { data: { data: { data: sites = [] } = {} } = {} } = useQuery(
    ['studySites'],
    () => api().get(apiEndpoints.sites),
    {
      onError: (err) => {
        dispatch(
          showSnackbar({
            type: 'error',
            message: err?.message || 'Something went wrong, please try again after sometime !'
          })
        );
      }
    }
  );

  const { mutate: handleUploadStudy } = useMutation(
    (values) => {
      setDisabled(true);
      const newStudy = new FormData();
      newStudy.append('siteId', values.siteId);
      newStudy.append('patientAge', values.patientAge);
      newStudy.append('patientSex', values.patientSex);
      newStudy.append('condition', values.condition);
      newStudy.append('ethnicity', values.ethnicity);
      newStudy.append(
        'symptoms',
        JSON.stringify(values.symptoms && values.symptoms.map((symptom) => symptom))
      );
      newStudy.append('refPhysician', values.refPhysician);
      newStudy.append('UUID', uuidv4());
      for (let i = 0; i < (values.caseFiles ? values.caseFiles.length : 0); i += 1) {
        newStudy.append('caseFiles', values.caseFiles[i]);
      }

      return api(
        { 'Content-Type': 'multipart/form-data' },
        {
          onUploadProgress: (progressEvent) => {
            const { loaded, total } = progressEvent;
            setProgress(Math.round((loaded * 100) / total));
          }
        }
      ).post(apiEndpoints.uploadStudy, newStudy);
    },
    {
      onSuccess: (res) => {
        resetForm();
        dispatch(
          showSnackbar({
            type: res.data.isSuccess ? 'success' : 'error',
            message: res.data.message
          })
        );
        setDisabled(false);
        setProgress(false);

        setModalMessage(
          `${res.data.message}. ${res.data.isSuccess ? 'The case is being processed and will take some time.' : ''
          }`
        );
        setOpenModal(true);
      },
      onError: (error) => {
        // resetForm();
        setDisabled(false);
        setProgress(false);

        dispatch(
          showSnackbar({
            type: 'error',
            message: error?.data?.msg || 'Something went wrong, please try after sometime !'
          })
        );

        setModalMessage(error?.data?.msg);
        setOpenModal(true);
      }
    }
  );

  const resetForm = () => {
    setFormValues({
      siteId: '',
      patientAge: '',
      patientSex: '',
      symptoms: [],
      condition: '',
      ethnicity: '',
      refPhysician: '',
      caseFiles: []
    });
    fileRef.current = null;
  };

  const handleModalClose = (event, reason) => {
    if (reason && (reason === 'backdropClick' || reason === 'escapeKeyDown')) return;
    setModalMessage('');
    setOpenModal(false);
  };

  return (
    <>
      <Formik
        validationSchema={uploadSchema}
        enableReinitialize
        initialValues={{
          siteId: formValues.siteId,
          patientAge: formValues.patientAge,
          patientSex: formValues.patientSex,
          symptoms: formValues.symptoms,
          condition: formValues.condition,
          ethnicity: formValues.ethnicity,
          refPhysician: formValues.refPhysician,
          caseFiles: formValues.caseFiles
        }}
        onSubmit={handleUploadStudy}
      >
        {({ handleSubmit, errors, ...rest }) => (
          <>
            <form className="material-form" onSubmit={handleSubmit} autoComplete="off">
              <Breadcrumbs aria-label="breadcrumb" my={3}>
                <Link color="inherit" to="/dashboard/worklist">
                  WorkList
                </Link>
                <Link color="textPrimary" to="/dashboard/worklist/upload" aria-current="page">
                  Upload Study
                </Link>
              </Breadcrumbs>

              <h2>Upload Patient Details</h2>
              <InputLabel htmlFor="siteId" style={marginStyles}>
                Site <span style={{ color: 'red' }}>*</span>
              </InputLabel>
              <TextField
                select
                name="siteId"
                variant="outlined"
                label="Select Site"
                fullWidth
                value={rest.values.siteId}
                onChange={handleChange}
                style={{ width: '70%' }}
                helperText={errors.siteId}
                error={Boolean(errors.siteId)}
              >
                <MenuItem value="Select Site" disabled>
                  Select Site
                </MenuItem>
                {sites.map((site) => (
                  <MenuItem key={site._id} value={site._id}>
                    {site.name}
                  </MenuItem>
                ))}
              </TextField>

              <InputLabel htmlFor="patientAge" style={marginStyles}>
                Age <span style={{ color: 'red' }}>*</span>
              </InputLabel>
              <TextField
                inputMode="numeric"
                type="text"
                name="patientAge"
                variant="outlined"
                label="Patient Age"
                fullWidth
                value={rest.values.patientAge}
                onChange={handleChange}
                style={{ width: '70%' }}
                helperText={errors.patientAge}
                error={Boolean(errors.patientAge)}
              />

              <Box mt={2}>
                <FormControl component="fieldset" error={Boolean(errors.patientSex)}>
                  <FormLabel component="legend">
                    Gender <span style={{ color: 'red' }}>*</span>
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-label="patientSex"
                    name="patientSex"
                    value={rest.values.patientSex}
                    onChange={handleChange}
                  >
                    <FormControlLabel value="female" control={<Radio />} label="Female" />
                    <FormControlLabel value="male" control={<Radio />} label="Male" />
                  </RadioGroup>
                  <FormHelperText error={Boolean(errors.patientSex)}>
                    {Boolean(errors.patientSex) && 'Required'}
                  </FormHelperText>
                </FormControl>
              </Box>

              {console.log(progress)}

              <Box mt={2}>
                <FormControl component="fieldset">
                  <FormLabel component="legend">Report Required</FormLabel>
                  <RadioGroup
                    row
                    aria-label="condition"
                    name="condition"
                    value={rest.values.condition}
                    onChange={handleChange}
                  >
                    <FormControlLabel value="Epilepsy" control={<Radio />} label="Epilepsy" />
                    <FormControlLabel value="Dementia" control={<Radio />} label="Dementia" />
                    {/* <FormControlLabel value="Alzeimers" control={<Radio />} label="Alzheimer's" /> */}
                    <FormControlLabel value="Parkinsons" control={<Radio />} label="Parkinsons" />
                    {/* <FormControlLabel value="MCI" control={<Radio />} label="MCI" /> */}
                    <FormControlLabel value="All-Structures" control={<Radio />} label="All Structures" />
                  </RadioGroup>
                </FormControl>
              </Box>

              <InputLabel htmlFor="symptoms" style={marginStyles}>
                Symptoms
              </InputLabel>
              <FormControl style={{ width: '100%' }}>
                <InputLabel htmlFor="symptoms"> Select Symptoms</InputLabel>
                <Select
                  name="symptoms"
                  multiple
                  value={rest.values.symptoms}
                  onChange={handleChange}
                  input={<Input />}
                  labelId="symptom"
                  renderValue={(selected) => (
                    <div>
                      {selected.map((value) => (
                        <Chip key={value} label={value} style={{ margin: '0px 2px' }} />
                      ))}
                    </div>
                  )}
                  style={{
                    width: '70%',
                    border: '1px solid lightgrey',
                    borderRadius: '5px',
                    padding: '10px 0'
                  }}
                >
                  {symptomsList.map((symptom) => (
                    <MenuItem key={symptom} value={symptom}>
                      {symptom}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <InputLabel htmlFor="ethncity" style={marginStyles}>
                Ethnicity
              </InputLabel>
              <TextField
                select
                name="ethnicity"
                variant="outlined"
                label="Select Ethinicity"
                style={{ width: '70%' }}
                value={rest.values.ethnicity}
                onChange={handleChange}
              >
                <MenuItem value="Indian">Indian</MenuItem>
                <MenuItem value="Caucasian">Caucasian</MenuItem>
                <MenuItem value="Australian">Australian</MenuItem>
                <MenuItem value="African">African</MenuItem>
              </TextField>

              <InputLabel htmlFor="refPhysician" style={marginStyles}>
                Ref Physician
              </InputLabel>
              <TextField
                name="refPhysician"
                variant="outlined"
                label="Referring Physician"
                style={{ width: '70%' }}
                value={rest.values.refPhysician}
                onChange={handleChange}
              />
              <div
               ref={dropZoneRef}
               onDrop={handleDrop}
               onDragOver={handleDragOver}
              >
              <InputLabel htmlFor="caseFiles" style={marginStyles}>
                Upload Dicom Files or Zip file <span style={{ color: 'red' }}>*</span>
              </InputLabel>
              <input
                ref={fileRef}
                hidden
                id="caseFiles"
                name="caseFiles"
                type="file"
                // // accept="*/dicom, .dcm, image/dcm, */dcm, .dicom"
                multiple
                onChange={handleChange}
              />
              <Box>
                <Button
                  type="button"
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    fileRef.current.click()
                  }}
                  style={{ marginRight: '20px' }}
                >
                  Drag & Drop or Choose Files
                </Button>
                <span>
                  {rest.values.caseFiles.length
                    ? `${rest.values.caseFiles.length} files selected`
                    : 'No file chosen'}
                </span>
              </Box>
              </div>

              <Box mt={3}>
                {progress ? (
                  <UploadProgress open={progress} onClose={!progress} progress={progress} />
                ) : (
                  <></>
                )}
              </Box>

              <Box mt={3}>
                <Button
                  type="reset"
                  variant="contained"
                  color="primary"
                  style={{ marginRight: '20px' }}
                  onClick={resetForm}
                >
                  {' '}
                  Reset{' '}
                </Button>
                <LoadingButton
                  variant="contained"
                  color="primary"
                  type="submit"
                  loading={!!progress}
                  disabled={disabled}
                >
                  {' '}
                  Upload{' '}
                </LoadingButton>
              </Box>
            </form>
            {!!openModal && (
              <UploadStudyModal
                open={openModal}
                onClose={handleModalClose}
                modalMessage={modalMessage}
              />
            )}
          </>
        )}
      </Formik>
    </>
  );
}

//
export default UploadStudy;
