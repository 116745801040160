import { useSelector } from 'react-redux';
import { Box } from '@material-ui/core';
import { Link } from 'react-router-dom';
// import logo from '../../assests/Images/INMED AI STUDIO final-01 1.png';
import neuroShield from '../../assests/Images/neuroshield-logo1.png';
import neuroVolumetry from '../../assests/Images/Neuroshiled-volumetry.png';
import backImage from '../../assests/Images/innovative-futuristic-classroom-students 1.png';
import studioImg from '../../assests/Images/INMED AI STUDIO final-03.png';
import vivoShield from '../../assests/Images/vivoshield.png';
import inmedStudio from '../../assests/Images/INMED AI STUDIO final-01 21.png'
import aiStudio from '../../assests/Images/INMED AI STUDIO final-01 1 (1).png'

function SiteAccess() {
  const { passcode } = localStorage.loggedInUser
    ? JSON.parse(localStorage.loggedInUser)
    : { passcode: '' };
  const hasBleedSubscription = useSelector((state) => state.loggedInUser.user.hasBleedSubscription);

  return (
    <>
      <Box position="relative" height="100%">
        <img src={backImage} alt="" style={{ height: '100%', width: '100%' }} />
        <Box>
          <Box
            boxShadow= "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"
            p={17}
            backgroundColor="white"
            borderRadius="50%"
            position="absolute"
            top="-28%"
            right="33%"
            width="36%"
            margin="auto"
            display='flex'
            alignItems='center'
            justifyContent='center'
            gap="40px"
            // position='fixed'
          >
            <img width="35%" style={{marginTop:'190px',marginLeft:'15px'}}  alt="logo" src={inmedStudio} />
            <img width="38%"  height='43px' style={{marginTop:'200px'}}  alt="logo" src={aiStudio} />

          </Box>

          {/* <Box
          // position='absolute'
          // top='2%'
            // width="60%"
            // alignContent="space-evenly"
            // margin="auto"
            // display="flex"
            // top="-10%"
            // width="100%"
            // gridTemplateColumns="repeat(2, minmax(200px, 2fr))"
            // gap="25px"
            // display='grid'
          > */}

          <Box
          position='absolute'
          top='8%'
          // left='11%'
          right="90.2%"
          >
            <a
              href={`${process.env.REACT_APP_VIVOSHIELD_WEB_URL}/login/${passcode}`}
              style={{ textDecoration: 'none', pointerEvents: 'none' }}
            >
              <Box
                backgroundColor='gainsboro'
                style={{ pointerEvents: 'none' }}
                position='relative'
                p={16}
                width="15%"
                height="15%"
                borderRadius="50%"
                border="12px solid #6F2289"
                left='90%'
              >
                <img
                  style={{ opacity: 0.5, position:'absolute', right:'33%',top:'20%'}}
                  width="40%"
                  height="30%"
                  alt="vivoshield"
                  src={vivoShield}
                />
                <Box
                  style={{
                    color: '#5c5959',
                    textDecoration: 'none',
                    fontSize: 20,
                    fontWeight: 800,
                    textAlign:'center',
                    position:'absolute',
                    top:'60%',
                    right:'28%'
                  }}
                >
                  VivoShield
                </Box>
              </Box>
            </a>
            </Box>
            <Box 
            position='absolute'
            top='45%'
            left='21%'
            >
            <a
              href={`${process.env.REACT_APP_NEUROFLO_WEB_URL}/login/${passcode}`}
              style={{ textDecoration: 'none', pointerEvents: hasBleedSubscription ? '' : 'none' }}
            >
              <Box
                backgroundColor={hasBleedSubscription ? 'white' : 'gainsboro'}
                style={{ pointerEvents: hasBleedSubscription ? '' : 'none' }}
                p={16}
                width="15%"
                height="15%"
                borderRadius="50%"
                border="12px solid #0086AB"
                position='absolute'
              >
                <img
                  style={{ opacity: hasBleedSubscription ? 2 : 0.5,position:'absolute',right:'15%',top:'20%'}}
                  width="70%"
                  height="30%"
                  alt="neuroshield"
                  src={neuroShield}
                />
                <Box
                  style={{
                    width:'50%',
                    color: hasBleedSubscription ? 'black' : '#5c5959',
                    textDecoration: 'none',
                    fontSize: 20,
                    fontWeight: 800,
                    textAlign: 'center',
                    position:'absolute',
                    top:'60%',
                    right:'24%'
                  }}
                >
                  Traumatic Brain Injury
                </Box>
              </Box>
            </a>
            </Box>
            <Box
            position='absolute'
            top='60%'
            left='43%'
            >
          <Link dis to="/dashboard/app" style={{ textDecoration: 'none', textAlign: 'right' }}>
            <Box
              display="flex"
              width='15%'
              height='15%'
              flexDirection="column"
              alignItems="flex-end"
              backgroundColor="white"
              p={16}
              position='absolute'
              borderRadius="50%"
              border='12px solid green'
            >
              <img width="70%" height='30%' alt="neuroshield" src={neuroShield} style={{position:'absolute',right:'15%',top:'20%'}} />
              <Box
                style={{
                  color: 'black',
                  textDecoration: 'none',
                  fontSize: 20,
                  fontWeight: 800,
                  position:'absolute',
                  top:'60%',
                  right:'25%',
                  width:'50%',
                  textAlign:"center"
                }}
              >
               Brain Volumetry
              </Box>
            </Box>
          </Link>
          </Box>
          <Box
          position='absolute'
          top='45%'
          left='65%'
          >
          <a
            href={`${process.env.REACT_APP_ORTHOSHIELD_WEB_URL}`}
            style={{ textDecoration: 'none', pointerEvents: 'none' }}
          >
            <Box
              backgroundColor='gainsboro'
              style={{ pointerEvents: 'none' }}
              width='15%'
              height='14%'
              p={16}
              borderRadius="50%"
              border='12px solid brown'
            >
              <img 
                width="40%"  
                height='auto' 
                alt="neuroshield" 
                src={studioImg} 
                style={{ opacity: 0.5, position:'absolute', right:'30%',top:'20%',margin:"auto"}}
              />
                <Box
                  style={{ color: 'black', textDecoration: 'none', fontSize: 19, fontWeight: 800, textAlign:'center', position:'absolute',
                  top:'65%',
                  right:'30%', }}
                >
                  Orthoshield
                </Box>
            </Box>
          </a>
          </Box>
          <Box
           position='absolute'
           top='7%'
           left='75%'
          >
            <a
              href={`${process.env.REACT_APP_ONCOSHIELD_WEB_URL}`}
              style={{ textDecoration: 'none', pointerEvents: 'none' }}
            >
              <Box
                backgroundColor='gainsboro'
                style={{ pointerEvents: 'none' }}
                p={16}
                width='15%'
                height='15%'
                borderRadius="50%"
                border='12px solid #E5465B'
                position='absolute'
              >
                <img 
                  width="40%"
                  style={{position:'absolute',right:'30%',top:'20%',margin:"auto"}}
                  alt="neuroshield" src={studioImg} 
                />
                <Box
                  style={{ color: 'black', textDecoration: 'none', fontSize: 19, fontWeight: 800,textAlign:'center', position:'absolute',top:'65%',right:"30%" }}
                >
                  Oncoshield
                </Box>
              </Box>
            </a>
          </Box>
          {/* </Box> */}
        </Box>
      </Box>
    </>
  );
}
export default SiteAccess;
