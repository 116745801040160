import { useCallback, useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';

import { useQuery } from 'react-query';
import { useApi } from '../../../config/api';
import { showSnackbar } from '../../../components/snackbar/actions';

const formField = {
  siteName: '',
  siteShortName: '',
  siteAddress: '',
  siteEntityId: '',
  institutionName: '',
  sitebleedSubscription: false
};

export const useSiteForm = ({ fieldValues, handleSiteForm, isEdit, handleSuccess }) => {
  const [formValues, setFormvalues] = useState(fieldValues || formField);
  const dispatch = useDispatch();
  const { api, apiEndpoints } = useApi();
  const [isFormModified, setIsFormModified] = useState(false);
  const [resetValue, setResetValue] = useState(fieldValues || formField);

  useEffect(() => {
    if (handleSuccess && !isEdit) {
      setFormvalues(formField);
    }
  }, [handleSuccess, isEdit]);

  const handleSetValues = useCallback(() => {
    if (fieldValues) {
      fieldValues.siteName = fieldValues?.name || '';
      fieldValues.siteShortName = fieldValues?.shortName || fieldValues?.short_name || '';
      fieldValues.siteAddress = fieldValues?.address || '';
      fieldValues.siteEntityId = fieldValues?.entityId || '';
      fieldValues.institutionName = fieldValues?.institutionName || '';
      fieldValues.sitebleedSubscription = fieldValues?.sitebleedSubscription || false;
      setFormvalues(fieldValues);
      setResetValue(fieldValues)
    }
  }, [fieldValues]);

  useEffect(() => {
    handleSetValues();
  }, [handleSetValues]);

  const { data: { data: { data: entities = [] } = {} } = {} } = useQuery(
    ['site-form-entities'],
    () => api().get(apiEndpoints.entities),
    {
      onError: (res) => {
        dispatch(
          showSnackbar({
            type: 'error',
            message: res?.response?.data?.msg
          })
        );
      },
      onSuccess: (res) => {
        if (res.message === 'Network Error') {
          dispatch(
            showSnackbar({
              type: 'error',
              message: res?.message
            })
          );
        }
      }
    }
  );
  const handleFormSubmit = async (values) => {
    try {
      await handleSiteForm(values);
      setIsFormModified(false);
      setResetValue(values);
    } catch (error) {
      console.error('Error:', error);
    }
  };
  const handleFormUpdate = (event) => {
    const { name, value, type } = event.target;

    if (type !== 'checkbox') {
      const caret = event.target.selectionStart;
      const element = event.target;
      window.requestAnimationFrame(() => {
        element.selectionStart = caret;
        element.selectionEnd = caret;
      });
    }

    setFormvalues((prevFieldValue) => ({
      ...prevFieldValue,
      [name]: name === 'sitebleedSubscription' ? !prevFieldValue.sitebleedSubscription : value
    }));
    setIsFormModified(true);
  };

  const handleFormArrayUpdate = (key, value) => {
    setFormvalues((prevFieldValue) => ({
      ...prevFieldValue,
      [key]: value
    }));
    setIsFormModified(true);
  };

  const resetForm = () => {
    if (isEdit) setFormvalues(resetValue);
    else setFormvalues(formField)
  };

  return {
    entities,
    formValues,
    handleFormUpdate,
    handleFormArrayUpdate,
    setFormvalues,
    formField,
    resetForm,
    isFormModified,
    setIsFormModified,
    handleSiteForm,
    fieldValues,
    handleFormSubmit
  };
};
