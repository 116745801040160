import plusFill from '@iconify/icons-eva/plus-fill';
// import { io } from 'socket.io-client';
import refreshFill from '@iconify/icons-eva/refresh-fill';
import { Icon } from '@iconify/react';
import axios from 'axios';
import { sentenceCase } from 'change-case';
import { saveAs } from 'file-saver';
import JSZip from 'jszip';
import JSZipUtils from 'jszip-utils';
import { filter, truncate } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';

// material
import {
  Box,
  Button,
  Card,
  CircularProgress,
  Container,
  Snackbar,
  SnackbarContent,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Tooltip,
  Typography
} from '@material-ui/core';
import {
  Check,
  CloudDownload,
  CloudUpload,
  Delete,
  Edit,
  FileCopyTwoTone,
  TrendingUp,
  Visibility
} from '@material-ui/icons';
import BlockIcon from '@material-ui/icons/Block';
import moment from 'moment';
// components
import { useMutation, useQuery } from 'react-query';
import DeleteConfirmation from '../../../components/delete-confirmation';
import Page from '../../../components/Page';
import SearchNotFound from '../../../components/SearchNotFound';
import {
  filterDataSelector,
  patientDataSelector,
  totalStudiesSelector
} from '../../../redux/selectors';
import Label from '../components/Label';
import Scrollbar from '../components/Scrollbar';
import {
  completeStatus,
  studyMarkedComplete,
  studyProcessed,
  TABLE_HEAD,
  TABLE_HEAD_NEW
} from './constant';
import { WorkListToolbar } from './index';
import DeleteStudy from './delete-Study'
import RejectReportModal from './RejectReportModal';
import TableHeader from '../../../components/table-header/index';
import { showSnackbar } from '../../../components/snackbar/actions';
import { getComparator } from '../../../utils/helpers';
import { useApi } from '../../../config/api';

// const socket = io(`${process.env.REACT_APP_API_SERVER_URL}`, {
//   transports: ['websocket']
// });
// socket.on('connect', () => {
//   console.log('Socket Connection Established');
// });

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) => _user.dicomPatientId.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function WorkList() {
  const [order, setOrder] = useState('desc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('uploadedDate');
  const [responseState, setResponseState] = useState({ error: false, msg: '' });
  const patientData = useSelector(patientDataSelector);
  const totalStudies = useSelector(totalStudiesSelector);
  const filter = useSelector(filterDataSelector);
  const { user } = useSelector((state) => state.loggedInUser);
  const [openRejectModal, setOpenRejectModal] = useState(false);
  const [rejectData, setRejectData] = useState({});
  const [state, setState] = React.useState({
    open: false,
    vertical: 'top',
    horizontal: 'center'
  });
  const { vertical, horizontal, open } = state;
  const handleClose = () => {
    setState({ ...state, open: false });
  };
  const [openModal, setOpenModal] = useState(false);
  const handleModal = () => {
    setOpenModal(!openModal);
  };

  const iconStyle = {
    backgroundColor: '#dcf2d8',
    color: '#4fa52e',
    margin: '0px 3px',
    padding: '1px 1px',
    cursor: 'pointer'
  };
  const iconStyleDisabled = {
    backgroundColor: '#f4f6f8',
    color: '#637381',
    margin: '0px 3px',
    padding: '1px 1px',
    cursor: 'not-allowed'
  };

  const dispatch = useDispatch();

  const { api, apiEndpoints } = useApi();

  const [params, setParams] = useState({
    filterPatientId: '',
    filterPatientAge: '',
    filterPatientGender: '',
    filterPatientNSID: '',
    filterReportStatus: '',
    filterBySite: '',
    filterDate: '',
    offset: 0,
    limit: 10,
    order: 'desc',
    orderBy: 'createdAt',
    sort: { createdAt: -1 }
  });

  const {
    data: { data: { data: { studies: worklist = [], total = 0 } = {} } = {} } = {},
    isLoading: worklistLoading,
    refetch: fetchApi
  } = useQuery(
    ['worklist', params],
    () =>
      api().get(apiEndpoints.getWorklist, {
        params: {
          patientId: params.filterPatientId,
          age: params.filterPatientAge,
          gender: params.filterPatientGender.toLowerCase(),
          nsid: params.filterPatientNSID,
          status: params.filterReportStatus,
          site: params.filterBySite,
          date: params.filterDate,
          limit: params.limit,
          offset: params.offset,
          order: params.order,
          orderBy: params.orderBy,
          sort: params.sort
        }
      }),
    {
      onSuccess: () => {},
      onError: (err) => {
        dispatch(
          showSnackbar({
            type: 'error',
            message: err.message
          })
        );
      }
    }
  );

  const handleFilters = (e) => {
    setParams((prev) => ({ ...prev, [e.target.name]: e.target.value, limit: 10, offset: 0 }));
  };

  useEffect(() => {
    // socket.on('upload:study', () => {
    //   fetchApi();
    // });
  }, [fetchApi]);

  const handleRequestSort = (event, property) => {
    if (property === 'action' || property === 'sites') return;
    setParams((prev) => {
      // Calculate the new order and orderBy values
      let newOrder = 'asc';
      if (prev.orderBy === property && prev.order === 'asc') {
        // eslint-disable-next-line no-unused-vars
        newOrder = 'desc';
      }
      return {
        ...prev,
        order: newOrder,
        orderBy: property,
        sort: newOrder === 'asc' ? { [property]: 1 } : { [property]: -1 }
      };
    });
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = patientData.map((n) => n._id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  // const handleClick = (patientId) => {
  //   const selectedIndex = selected.indexOf(patientId);
  //   let newSelected = [];
  //   if (selectedIndex === -1) {
  //     newSelected = newSelected.concat(selected, patientId);
  //   } else if (selectedIndex === 0) {
  //     newSelected = newSelected.concat(selected.slice(1));
  //   } else if (selectedIndex === selected.length - 1) {
  //     newSelected = newSelected.concat(selected.slice(0, -1));
  //   } else if (selectedIndex > 0) {
  //     newSelected = newSelected.concat(
  //       selected.slice(0, selectedIndex),
  //       selected.slice(selectedIndex + 1)
  //     );
  //   }
  //   setSelected(newSelected);
  // };

  const emptyRows =
    filter.offset > 0 ? Math.max(0, (1 + filter.offset) * filter.limit - totalStudies) : 0;

  const handleChangePage = async (event, newPage) => {
    const newOffset = newPage * params.limit;
    setParams((prev) => ({ ...prev, offset: newOffset }));
  };

  const handleChangeRowsPerPage = (event) => {
    const newLimit = parseInt(event.target.value, 10);
    setParams((prev) => ({ ...prev, offset: 0, limit: newLimit }));
  };

  const getDicomSignedUrls = (patientId, siteId) => {
    axios
      .get(
        `${process.env.REACT_APP_API_SERVER_URL}/api/studies/getSignedUrl/${siteId}/${patientId}`
      )
      .then((response) => {
        const timestamp = Date.now();
        localStorage.setItem(
          `urls_${siteId}_${patientId}_${timestamp}`,
          JSON.stringify(response.data.message)
        );
        window.open(
          `/papaya.html?sid=${siteId}&pid=${patientId}&source=web&timestamp=${timestamp}`,
          '_blank'
        );
      });
  };

  const markComplete = (studyId, siteId) => {
    axios
      .get(
        `${process.env.REACT_APP_API_SERVER_URL}/api/studies/update-status/${studyId}/${siteId}/Complete`
      )
      .then(() => {
        handleRefreshClick();
      });
  };

  const markRejected = (studyId, siteId) => {
    setOpenRejectModal(true);
    setRejectData({ studyId, siteId });
  };

  const handleRejectModalClose = () => {
    setOpenRejectModal(false);
    setRejectData({});
  };

  const getStudyFiles = (patientId, siteId) => {
    axios
      .get(
        `${process.env.REACT_APP_API_SERVER_URL}/api/studies/getSignedUrl/${siteId}/${patientId}`
      )
      .then((response) => {
        const timestamp = Date.now();

        const zip = new JSZip();
        const folderName = `${patientId}_${siteId}_${timestamp}`;

        const urlData = response.data.message;
        let count = 0;
        urlData.forEach((url) => {
          const name = url.substring(url.lastIndexOf('/') + 1, url.lastIndexOf('?'));

          JSZipUtils.getBinaryContent(url, (err, data) => {
            if (err) {
              console.log('url err : ', err);
            }
            if (data) {
              zip.file(name, data, { binary: true });
            }
            count += 1;
            if (count === urlData.length) {
              zip
                .generateAsync({ type: 'blob' })
                .then((blob) => {
                  saveAs(blob, folderName);
                })
                .catch((e) => {
                  console.log(e);
                });
            }
          });
        });
      });
  };

 
  const fetchStudy = () => {
        fetchApi();
        
  };
  const handleCopyClick = async (patientId) => {
    try {
      await navigator.clipboard.writeText(patientId);

      dispatch(
        showSnackbar({
          type: 'success',
          message: 'Copied!'
        })
      );
    } catch (err) {
      dispatch(
        showSnackbar({
          type: 'error',
          message: 'Failed to copy!'
        })
      );
    }
  };

  const handleRefreshClick = () => {
    setParams({
      filterPatientId: '',
      filterPatientAge: '',
      filterPatientGender: '',
      filterPatientNSID: '',
      filterReportStatus: '',
      filterBySite: '',
      filterDate: '',
      offset: 0,
      limit: 10
    });
    window.location.reload();
  };

  const handleMarkComplete = (studyId, siteId) => {
    if (window.confirm('Do you want to change the status to "Complete"?')) {
      setResponseState({ error: false, msg: 'Successfully marked as completed!!' });
      setState({ open: true, vertical: 'top', horizontal: 'right' });
      markComplete(studyId, siteId);
    }
  };

  const handleMarkReject = (rejectData, values) => {
    axios
      .get(
        `${process.env.REACT_APP_API_SERVER_URL}/api/studies/update-status/${rejectData.studyId}/${rejectData.siteId}/Rejected`,
        {
          params: {
            rejectReason: values.rejectReason
          }
        }
      )
      .then((response) => {
        dispatch(
          showSnackbar({
            type: 'success',
            message: response.data.message
          })
        );
        handleRejectModalClose();
        handleRefreshClick();
      })
      .catch(() => {
        dispatch(
          showSnackbar({
            type: 'error',
            message: 'Failed to Copy..!'
          })
        );
        handleRejectModalClose();
      });
  };

  const filteredStudies = applySortFilter(
    worklist,
    getComparator(order, orderBy),
    params.filterPatientId
  );
  const isStudyNotFound = filteredStudies.length === 0;

  return (
    <Page title="WorkList | Neuro-Shield">
      <Container maxWidth={false}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Worklist
          </Typography>
          <Stack direction="row" justifyContent="felx-end">
            <Button
              variant="contained"
              sx={{ marginRight: 2 }}
              onClick={handleRefreshClick}
              startIcon={<Icon icon={refreshFill} />}
            >
              Refresh
            </Button>
            <Button
              variant="contained"
              component={RouterLink}
              to="/dashboard/worklist/upload"
              startIcon={<Icon icon={plusFill} />}
            >
              Upload Study
            </Button>
          </Stack>
        </Stack>
        <Snackbar
          severity={responseState.error ? 'red' : 'green'}
          autoHideDuration={3000}
          anchorOrigin={{ vertical, horizontal }}
          open={open}
          onClose={handleClose}
          key={vertical + horizontal}
        >
          <SnackbarContent
            style={{ backgroundColor: responseState.error ? 'red' : 'green' }}
            message={responseState.msg}
          />
        </Snackbar>

        <Card>
          <WorkListToolbar
            numSelected={selected.length}
            onFilterChange={handleFilters}
            filteredData={params}
          />
          {worklistLoading ? (
            <CircularProgress
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                margin: '0 auto'
              }}
            />
          ) : (
            <>
              <Scrollbar>
                <TableContainer sx={{ minWidth: 800 }}>
                  <Table>
                    <TableHeader
                      order={order}
                      orderBy={orderBy}
                      headLabel={user.role === 'User' ? TABLE_HEAD : TABLE_HEAD_NEW}
                      rowCount={patientData.length}
                      numSelected={selected.length}
                      onRequestSort={handleRequestSort}
                      onSelectAllClick={handleSelectAllClick}
                      showSelectAll={false}
                    />

                    <TableBody>
                      {worklist.map((row) => {
                        const {
                          _id,
                          patientId,
                          patientGender,
                          processingStatus,
                          UUID,
                          uploadedDate,
                          patientAge,
                          site,
                          studyId,
                          patientCondition,
                          patientName,
                          name,
                          rejectReason,
                          dicomPatientId,
                          isLesion,
                          isArtefact,
                          lesionProbability
                          // Normal_or_Lesion
                        } = row;
                        console.log(row,'row')
                        const isItemSelected = selected.indexOf(_id) !== -1;
                        const newPatientGender = patientGender === 'male' ? 'M' : 'F';
                        return (
                          <TableRow
                            hover
                            key={_id}
                            tabIndex={-1}
                            role="checkbox"
                            selected={isItemSelected}
                            aria-checked={isItemSelected}
                          >
                            <TableCell align="left">{UUID || 'NA'}</TableCell>
                            <TableCell align="left">
                              <Stack
                                spacing={2}
                                direction="row"
                                sx={{ justifyContent: 'space-between' }}
                              >
                                <Tooltip title={dicomPatientId} arrow placement="bottom-end">
                                  <span>{truncate(dicomPatientId, { length: '20' }) || 'NA'}</span>
                                </Tooltip>
                                <FileCopyTwoTone
                                  sx={{ cursor: 'pointer' }}
                                  variant="contained"
                                  onClick={() => handleCopyClick(dicomPatientId)}
                                />
                              </Stack>
                            </TableCell>
                            {user.role === 'User' && (
                              <TableCell align="left">
                                {patientName.replace(/[^a-zA-Z ]/g, '')}
                              </TableCell>
                            )}
                            <TableCell align="left">{newPatientGender || 'NA'}</TableCell>
                            <TableCell align="left">{patientAge || 'NA'}</TableCell>
                            {user.role === 'Admin' && (
                              <TableCell align="left" sx={{ width: '12%' }}>
                                {name || 'NA'}
                              </TableCell>
                            )}
                            <TableCell align="left">
                              <Label
                                variant="ghost"
                                color={
                                  (processingStatus === 'Failed' && 'error') ||
                                  (processingStatus === 'Complete 30 S' && 'success') ||
                                  (processingStatus === 'Complete 11 S' && 'success') ||
                                  (processingStatus === 'Complete 13 S' && 'success') ||
                                  (processingStatus === 'Complete 21 S' && 'success') ||
                                  (processingStatus === 'Complete 7 S' && 'success') ||
                                  (processingStatus === 'Complete' && 'success') ||
                                  (processingStatus === 'Completed' && 'success') ||
                                  (processingStatus === 'In Review' && 'info') ||
                                  (processingStatus === 'Processing' && 'info') ||
                                  (processingStatus === 'Cancelled' && 'error') ||
                                  (processingStatus === 'Rejected' && 'error')
                                }
                              >
                                {site === '605c1c5a7365ba2c7923e602' &&
                                completeStatus.includes(processingStatus)
                                  ? sentenceCase(processingStatus || 'NA')
                                  : sentenceCase(processingStatus || 'NA')}
                              </Label>
                              {processingStatus === 'Processing' ? null : (
                                <>
                                  {user.role === 'Admin' &&
                                    (isLesion === true ? (
                                      <Typography
                                        sx={{
                                          fontSize: '0.7rem',
                                          mt: 1,
                                          ml: 0.5,
                                          fontWeight: '600',
                                          color: 'green'
                                        }}
                                      >
                                        {lesionProbability
                                          ? `Lesion Present : ${lesionProbability}`
                                          : null}
                                      </Typography>
                                    ) : (
                                      <Typography
                                        sx={{
                                          fontSize: '0.7rem',
                                          mt: 1,
                                          ml: 0.5,
                                          fontWeight: '600',
                                          color: 'green'
                                        }}
                                      >
                                        Lesion Absent
                                      </Typography>
                                    ))}
                                  {!!rejectReason && (
                                    <Typography sx={{ fontSize: '0.7rem', mt: 1, ml: 0.5 }}>
                                      {rejectReason}
                                    </Typography>
                                  )}
                                  {user.role === 'Admin' &&
                                    (isArtefact === true ? (
                                      <Typography
                                        sx={{
                                          fontSize: '0.7rem',
                                          mt: 1,
                                          ml: 0.5,
                                          fontWeight: '600',
                                          color: 'green'
                                        }}
                                      >
                                        Artefact Present
                                      </Typography>
                                    ) : (
                                      <Typography
                                        sx={{
                                          fontSize: '0.7rem',
                                          mt: 1,
                                          ml: 0.5,
                                          fontWeight: '600',
                                          color: 'green'
                                        }}
                                      >
                                        Artefact Absent
                                      </Typography>
                                    ))}
                                </>
                              )}
                            </TableCell>
                            <TableCell align="left">{patientCondition || 'NA'}</TableCell>
                            <TableCell align="left">
                              {moment(uploadedDate).format('MMM DD, YYYY, h:mm a') || 'NA'}
                            </TableCell>
                            <TableCell align="left" sx={{ minWidth: '150px' }}>
                              {user.role === 'Admin' ? (
                                <Box>
                                  <RouterLink
                                    to={{ pathname: `/dashboard/worklist/edit-study/${_id}` }}
                                    state={{ age: patientAge, gender: patientGender, siteId: site }}
                                  >
                                    <Tooltip title="update-worklist" arrow>
                                      <Edit style={iconStyle} />
                                    </Tooltip>
                                  </RouterLink>

                                  {studyProcessed.includes(processingStatus) &&
                                  user.role === 'Admin' ? (
                                    <RouterLink
                                      to={{ pathname: `/dashboard/worklist/report?studyId=${_id}` }}
                                    >
                                      <Tooltip title="report" arrow>
                                        <TrendingUp style={iconStyle} />
                                      </Tooltip>
                                    </RouterLink>
                                  ) : (
                                    <Tooltip title="report" arrow>
                                      <TrendingUp style={iconStyleDisabled} />
                                    </Tooltip>
                                  )}

                                  <Tooltip title="Download files" arrow placement="bottom-end">
                                    <CloudDownload
                                      onClick={() => getStudyFiles(patientId, site)}
                                      style={iconStyle}
                                    />
                                  </Tooltip>

                                  {studyProcessed.includes(processingStatus) ? (
                                    <RouterLink
                                      to={{
                                        pathname: `/dashboard/worklist/upload-segmentation?studyId=${_id}`
                                      }}
                                    >
                                      <Tooltip
                                        title="Upload segmentation"
                                        arrow
                                        placement="bottom-end"
                                      >
                                        <CloudUpload style={iconStyle} />
                                      </Tooltip>
                                    </RouterLink>
                                  ) : (
                                    <Tooltip
                                      title="Upload segmentation"
                                      arrow
                                      placement="bottom-end"
                                    >
                                      <CloudUpload style={iconStyleDisabled} />
                                    </Tooltip>
                                  )}

                                  <Tooltip title="Mark as Rejected" arrow placement="bottom-end">
                                    <BlockIcon
                                      onClick={() => markRejected(_id, site)}
                                      style={iconStyle}
                                    />
                                  </Tooltip>
                                  {studyMarkedComplete.includes(processingStatus) ||
                                  processingStatus === 'Failed' ? (
                                    <Tooltip title="Mark as Complete" arrow placement="bottom-end">
                                      <Check style={iconStyleDisabled} />
                                    </Tooltip>
                                  ) : (
                                    <Tooltip title="Mark as Complete" arrow placement="bottom-end">
                                      <Check
                                        onClick={() => handleMarkComplete(_id, site)}
                                        style={iconStyle}
                                      />
                                    </Tooltip>
                                  )}
                                  <Tooltip title="view" arrow placement="bottom-end">
                                    <Visibility
                                      onClick={() => getDicomSignedUrls(patientId, site)}
                                      style={iconStyle}
                                    />
                                  </Tooltip>
                                    <DeleteStudy
                                    id={studyId}
                                    refetchStudy={fetchStudy}
                                    />
                                </Box>
                              ) : (
                                <Box>
                                  {/* <Tooltip title="patient-history" arrow placement="bottom-start"><Assignment style={iconStyle} /></Tooltip> */}
                                  {studyProcessed.includes(processingStatus) ? (
                                    <RouterLink
                                      to={{ pathname: `/dashboard/worklist/report?studyId=${_id}` }}
                                    >
                                      <Tooltip title="report" arrow>
                                        <TrendingUp style={iconStyle} />
                                      </Tooltip>
                                    </RouterLink>
                                  ) : (
                                    <Tooltip title="report" arrow>
                                      <TrendingUp style={iconStyleDisabled} />
                                    </Tooltip>
                                  )}
                                  <Tooltip title="view" arrow placement="bottom-end">
                                    <Visibility
                                      onClick={() => getDicomSignedUrls(patientId, site)}
                                      style={iconStyle}
                                    />
                                  </Tooltip>
                                </Box>
                              )}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                      {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </TableBody>

                    {isStudyNotFound && (
                      <TableBody>
                        <TableRow>
                          <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                            <SearchNotFound searchQuery={filter.filterPatientId} />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                  </Table>
                </TableContainer>
              </Scrollbar>

              <TablePagination
                rowsPerPageOptions={[5, 10, 15]}
                component="div"
                count={total}
                rowsPerPage={params.limit}
                page={Math.floor(params.offset / params.limit)}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </>
          )}
        </Card>
      </Container>
      {openRejectModal && (
        <RejectReportModal
          open={openRejectModal}
          onClose={handleRejectModalClose}
          rejectData={rejectData}
          handleMarkReject={handleMarkReject}
        />
      )}
    </Page>
  );
}
